/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// IE CustomEvent polyfill
(function () {
  if ( typeof window.CustomEvent === "function" ) {
    return false;
  }
  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

// main sage js
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        // add custom media query sizes for Foundation Interchange
        Foundation.Interchange.SPECIAL_QUERIES.smallretina = 'only screen and (min-width: 1px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min-resolution: 192dpi), only screen and (min-width: 1px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.mediumretina = 'only screen and (min-width: 641px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 641px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 641px) and (min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min-resolution: 192dpi), only screen and (min-width: 641px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.largeretina = 'only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1025px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min-resolution: 192dpi), only screen and (min-width: 1025px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.xlargeretina = 'only screen and (min-width: 1921px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1921px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min-resolution: 192dpi), only screen and (min-width: 1921px) and (min-resolution: 2dppx)';

        // add minimum checkbox validator for Foundation Abide
        Foundation.Abide.defaults.validators.checkbox_limit = function($el, required, parent) {
          var group = parent.closest('.checkbox-group');
          var min = group.attr('data-validator-min');
          var checked = group.find(':checked').length;
          if (checked >= min) {
            // clear label highlight
            group.find('label').each(function() {
              $(this).removeClass('is-invalid-label');
            });
            // clear checkbox error 
            group.find(':checkbox').each(function() {
              $(this).removeClass('is-invalid-input').removeAttr('data-invalid');
            });
            group.find('.form-error').hide();
            return true;
          } else {
            // label highlight
            group.find('label').each(function() {
              $(this).addClass('is-invalid-label');
            });
            // checkbox error 
            group.find(':checkbox').each(function() {
              $(this).addClass('is-invalid-input').attr('data-invalid');
            });
            group.find('.form-error').show();
            return false;
          }
        };

        // add plz pattern for Foundation Abide
        Foundation.Abide.defaults.patterns.plz = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;

        // add leap_year pattern for Foundation Abide
        Foundation.Abide.defaults.patterns.leap_year = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

        // init Foundation JavaScript
        $(document).foundation(); 

        // set default Sticky size for Foundation Sticky
        Foundation.Sticky.defaults.stickyOn = 'large';

        // Mobile Menu
        Foundation.DropdownMenu.defaults.closeOnClick = true;       

        // add no-touch class for desktop browsers
        var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
        if (!touchsupport) { 
            //document.documentElement.className += " no-touch ";
            $('html').addClass('no-touch');
        }

        // check for IE/Edge
        if(/msie|trident|edge/g.test(navigator.userAgent.toLowerCase())) { 
           $('body').addClass('ie-edge');
           var version = navigator.userAgent.match( /(MSIE |Trident.*rv[ :])([0-9]+)/ ); 
           if(version != null) { 
               $('body').addClass('ie-ver-'+version[2]);
           } 

          $(".has-ie-fallback").each(function () {
            var $image = $(this);
            var fallback = $image.attr("data-ie-fallback");
            $image.attr("src", fallback);
          });
           
        }

        // show whatsApp Button
        if (Foundation.MediaQuery.is('small only')) {
          if( /windows phone|Android|webOS|iPhone|blackberry|Opera Mini/i.test(navigator.userAgent) ) {
           $('.whatsapp-share-button').css("display", 'inline-block');
          }
        }

        // Share Button popups
        $('.share-button').click(function(e){
          if (this.classList.contains("share-popup")) { 
            e.preventDefault();
            var $this = $(this),
                href  = $this.attr('href'),
                width       = 400,
                height      = 500,
                left        = (window.innerWidth/2) - width/2,
                top         = (window.innerHeight/2) - height/2,
                config      = 'status = no,height = '+width+',width  = '+height+',resizable = yes,screenY  = '+top+',screenX = '+left+',toolbar = no,menubar = no,scrollbars = no,location = no,directories = no';
            window.open(href, '', config);
          }           
        });

        // change body class on load
        if ($(window).scrollTop() > 50) {
          $('body').addClass('solid-header');
        } else {
          $('body').removeClass('solid-header');
        } 

        // change body class on scroll
        $(window).scroll(function () {
          var currentScrollTop = $(window).scrollTop();
  
          if (currentScrollTop > 50) {
            $('body').addClass('solid-header');
          } else {
            if (!$('body').hasClass('reveal-solid')) {
              $('body').removeClass('solid-header');
            }  
            if ($('body').hasClass('reveal-solid')) {
              $('body').removeClass('reveal-solid');
            }                      
          }       
        });

        $('[data-interchange]').each(function () {
          //$(this).on("replaced.zf.interchange", function() {
          //  $(this).addClass('interchanged');
          //  console.log('interchanged');                    
          //});
          $(this).on('load', function() {
            $(this).addClass('loaded');                         
          });             
        });        

        $('[data-reveal]').on("closeme.zf.reveal", function(e) {
          var currentScrollTop = $(window).scrollTop();
  
          if (currentScrollTop > 50) {
            $('body').addClass('reveal-solid');
          } else {
            $('body').removeClass('reveal-solid');
          } 
        });        

        // Menu popup
        $('.menu-popup').on("open.zf.reveal", function(e) {
          //$('#main-header').addClass('popup-menu-open');
          $('body').addClass('popup-menu-open');
        });
        $('.menu-popup').on("closed.zf.reveal", function(e) {
          //$('#main-header').removeClass('popup-menu-open');
          $('body').removeClass('popup-menu-open').removeClass('reveal-solid');       
        }); 

        // Toggle Menu popup
        $('.toggle-menu-popup').on("open.zf.reveal", function(e) {
          $('body').addClass('toggle-menu-popup-open');
        });
        $('.toggle-menu-popup').on("closed.zf.reveal", function(e) {
          $('body').removeClass('toggle-menu-popup-open');       
        });               

        // lightbox popup
        $('.lightbox').on("open.zf.reveal", function(e) {
          $('html').addClass('lightbox-open');
          //$('html').css({'overflow-y': 'hidden'});
          //$('body').css({'overflow-y': 'hidden'});
          //$('.reveal-overlay').css({'overflow-y': 'scroll'});
          //$(window).trigger('resize');
        });
        $('.lightbox').on("closed.zf.reveal", function(e) {
          $('html').removeClass('lightbox-open');
          //$('html').css({'overflow-y': 'inherit'});
          //$('body').css({'overflow-y': 'inherit'});
          //$('.reveal-overlay').css({'overflow-y': 'inherit'});                 
        });

        // Product popup
        $('.cpt-product > a').click(function(){
          $(this).parent().toggleClass('active');
          $('#product-popup').foundation('toggle');
          return false;
        });
        $('#product-popup').on("closed.zf.reveal", function(e) {
          $('.cpt-product').removeClass('active');       
        });

        // Dropdown menu
        $('[data-dropdown-menu]').on("show.zf.dropdownmenu", function(e) {
          $('#main-header').addClass('dropdown-menu-open');
          $('body').addClass('dropdown-menu-open');
        });
        $('[data-dropdown-menu]').on("hide.zf.dropdownmenu", function(e) {
          $('#main-header').removeClass('dropdown-menu-open');
          $('body').removeClass('dropdown-menu-open');       
        });

        // Toggle menu
        $('[data-off-canvas]').on("opened.zf.offcanvas", function(e) {
          $('#toggle-menu').addClass('open');
          if ($(this).hasClass('full')) {
            $('body').addClass('full-off-canvas');
          }
          $('#main-header').addClass('toggle-menu-open');
          $('body').addClass('no-scroll toggle-menu-open');
          $('#toggle-menu-button').addClass('active');
        });
        $('[data-off-canvas]').on("closed.zf.offcanvas", function(e) {
          $('#toggle-menu').removeClass('open');
          $('#main-header').removeClass('toggle-menu-open');
          $('body').removeClass('no-scroll toggle-menu-open');
          $('#toggle-menu-button').removeClass('active');          
        });

        $('#toggle-menu .menu-item-has-children > a').click(function(){
           var i = 0;
           var parent = $(this).parent();
           $('#toggle-menu ul > li').each(function(){
             if($('#toggle-menu ul > li').index(parent)!==i){
               $(this).removeClass('open');
               $(this).find('*').removeClass('open');
             }
             i++;
           });
           $(this).parent().toggleClass('open');
           $(this).toggleClass('open');
           $(this).siblings('.foldable-content').toggleClass('open');
           return false;
        });     
        
        $('.off-canvas').on('scroll', function () {
          if ($(this).scrollTop() > 0) {
            $('#main-header').addClass('toggle-menu-scrolled');
          } else {
            $('#main-header').removeClass('toggle-menu-scrolled');
          }
        });    

        // Smooth scrolling
        if(window.location.hash) {
          $('html, body').animate({
              scrollTop: $(window.location.hash).offset().top - $('#main-header').height() + 'px'
          }, 1000);
        }         

        // Scrolldown Button scrolling
        $('.scrolldown-button').click(function(){
          var nextSection = $(this).closest('section').next();

          $('html, body').animate({
              scrollTop: nextSection.offset().top
          }, 1000);            
        });           

        // wpcf7
        $('.wpcf7').on('wpcf7:invalid', function(e) {
          if ($('.wpcf7-validation-errors').length) {
            $('html, body').animate({
                scrollTop: $('.wpcf7').offset().top - 70
            }, 500);            
          } else {
            $('html, body').animate({
                scrollTop: $('.wpcf7-not-valid').first().offset().top - 100
            }, 500);               
          }
        });

        $('.wpcf7').on('wpcf7:mailsent', function(e) {
          if ($('.wpcf7-mail-sent-ok').length) {
            $('html, body').animate({
                scrollTop: $('.wpcf7').offset().top - 70
            }, 500);            
          } else {
            $('html, body').animate({
                scrollTop: $('.wpcf7').offset().top - 100
            }, 500);               
          }
        });

        $('.wpcf7-form').on('submit', function() {
           $(this).find('.wpcf7-submit').attr('disabled', true);
        });
        
        $('.wpcf7').on('wpcf7submit', function (e) {
           $(this).find('.wpcf7-submit').removeAttr('disabled');
        });

        // wpcf7cf
        $("[data-class='wpcf7cf_group']").each(function () {
          var $group = $(this);
          var $groupID = $group.attr('data-id');
          $group.find('input,select,textarea').each(function () {
            var originalName = $(this).attr('name');
            $(this).attr('data-temp-name', originalName );
            $(this).attr('name', $groupID + '-' + originalName );
            //console.log('name: ' + $(this).attr('name'));
          });

          $group.on('wpcf7cf_show_group', function(e) {
            //console.log('show_group');
            $(this).find('input,select,textarea').each(function () {
              $(this).attr('name', $(this).attr('data-temp-name') );
              //console.log('name: ' + $(this).attr('name'));
            });            
          });
          $group.on('wpcf7cf_hide_group', function(e) {
            //console.log('hide_group');
            $(this).find('input,select,textarea').each(function () {
              var originalName = $(this).attr('name');
              $(this).attr('name', $groupID + '-' + originalName );              
              //console.log('name: ' + $(this).attr('name'));
            });            
          });          
        });        

        // Search
        function initSearch() { 
          $('.search-form .search-button').on('click', function(e) {
            e.preventDefault();
            $(this).parents('form').submit();
            return false;
          });           
          $('.search-form .search-input').keypress(function (e) {
            if (e.which === 13) {
              $(this).parents('form').submit();
              return false;
            }
          });                           
        }

        initSearch(); 

        $('.search-popup').on("open.zf.reveal", function(e) {
          $('.search-form .search-input').focus();
        });
        $('.search-popup').on("closed.zf.reveal", function(e) {
          $('.search-form .search-input').blur();       
        });        


        // If cookie-notice cookie is not set, show .new-cookie-notice
        if ((!readCookie('cookie-notice')) && (!$('body').hasClass('iframe'))) {
          $('.new-cookie-notice').show();
        } else {
          $('.new-cookie-notice').hide();
        }
    
        // Add the event that closes the popup and sets the cookie to not show again until 3650 days have passed.
        $('#close-cookie-notice').click(function(){
          $(".new-cookie-notice").slideUp(150);
          createCookie('cookie-notice', true, 3650);
          return false;
        });                

        // Custom wpml cookie
        if (SiteParameters.wpml_current_lang && SiteParameters.activate_custom_browser_redirect) {
          $('.wpml-ls-link').click(function(){
            var setLang = $(this).attr('data-lang');
            createCookie('language_switcher_was_used', setLang, 365);
          });           
        } 

        // Load product menu items
        function ajax_load_product_menu_items (currentGroupID) {

          $.ajax({
            url : SiteParameters.ajax_url,
            type : 'GET',
            dataType: 'html',
            data: ({
              action: 'load_product_menu_items',
              currentgroupid: currentGroupID,
              currentpostid: SiteParameters.post_id
            }),      
            beforeSend: function (xhr) {
              
            },
            success: function(data){
              if(data) { 
                var parsedData = jQuery.parseJSON(data);
                if (parsedData.output){
                  $('#toggle-menu-group-popup-link').html(parsedData.current_group_name);
                  $('#toggle-category-menu ').html(parsedData.output);
                }
                if (parsedData.current_group_link){
                  $('#toggle-menu-button').attr('href',parsedData.current_group_link);
                }                               
              }
            },
            error: function(data) {
              console.log("Error: " + data);
              return false;
            },
            complete: function() {    
              //console.log("Complete");
              $('#toggle-menu-popup').foundation('close');                         
            }
          });
        }        
        $('.category-trigger').click(function(){
          var currentGroupID = $(this).attr("data-current-group-id");
          $('#toggle-menu-popup .menu-item.active a .icon').remove();
          $('#toggle-menu-popup .menu-item.active').removeClass('active');
          $(this).append( ' <span class="icon icon-check"></span>' );
          $(this).parent().addClass('active');
          ajax_load_product_menu_items(currentGroupID);
        });        

        // fire initial resize
        //$(window).trigger('resize');

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        if ( $('#gunfinder-widget').length ) {
          document.addEventListener('gunfinder:search:load', function () {
            var $gfsWidget = $('#gunfinder-widget');
            var shopButtonText = $gfsWidget.attr("data-shop-button-text");
            var $gfsWidgetHeadline = $gfsWidget.find('.gunfinder-widget-headline');
            var $gfsHeadlineItemCount = $gfsWidget.find('.headline-item-count');
            var $gfsItems = $gfsWidget.find('.gfs-item');
            var gfsItemsLength = $gfsWidget.find('.gfs-item').length;
            $gfsWidget.find('style').remove();

            $gfsWidget.find('a').attr("target", '_blank' );

            $gfsHeadlineItemCount.html(gfsItemsLength);
            if (gfsItemsLength === 1) {
              //$gfsWidgetHeadline.html(GunFinderStrings.one_result_headline);
            }            

            $gfsItems.each(function() {
              var $this = $(this);
              var $gfsItemTop = $this.find('.gfs-top');
              var $gfsItemTitle = $this.find('a.gfs.gfs-truncate');
              var $gfsDetails = $this.find('.gfs-details');
              var $gfsSource = $this.find('.gfs-source');
              var gfsSourceURL = $gfsSource.html();
              var $gfsImage = $this.find('.gfs-picture a img');
              var gfsImageURL = $this.find('.gfs-picture a img').attr('src');
              var gfsNewImageURL = gfsImageURL.replace('/tr:rt-auto,dpr-2.0,w-150,h-100,fo-auto', '/tr:rt-auto,w-200,dpr-2.0');
              var $gfsExtras = $this.find('.gfs-extras');
              var $gfsExtraOne = $this.find('.gfs-extra:eq(0)');
              var $gfsExtraTwo = $this.find('.gfs-extra:eq(1)');
              var $gfsExtraThree = $this.find('.gfs-extra:eq(2)');
              var gfsShopButton = '<div class="button-wrap"><a class="button rounded shop-button red" href="' + gfsSourceURL + '" target="_blank">' + shopButtonText + '</a></div>';
              //console.log('gfsNewImageURL: ' + gfsNewImageURL);
              $gfsImage.attr('src', gfsNewImageURL);
              $gfsExtraThree.insertAfter( $gfsItemTitle );
              //$gfsExtraThree.prepend( $gfsDealerText );
              $gfsExtraOne.hide();
              $gfsExtraTwo.hide();              
              if (Foundation.MediaQuery.atLeast('xlarge')) {
                $gfsExtras.appendTo( $this );     
              } else {
                $gfsExtras.appendTo( $gfsDetails );
              }        

              if (gfsSourceURL) {
                $(gfsShopButton).appendTo( $gfsDetails );
              }                      
            });

            $gfsItems.slice(4, gfsItemsLength).addClass('hidden');
            if ($gfsWidget.find('.gfs-item.hidden').length > 0) {
              $("#gunfinder-button-wrap").removeClass('hide');
            }   

            $("#gunfinder-more-button").on('click', function (e) {
              e.preventDefault();
              $(".gfs-item.hidden").slice(0, 4).slideDown({
                start: function () {
                  $(this).css({display: "flex"});
                }
              }).removeClass('hidden');
              
              if ($gfsWidget.find('.gfs-item.hidden').length === 0) {
                $("#gunfinder-button-wrap").addClass('hide');
              }
            });             

            if (gfsItemsLength > 0) {
              $('.gunfinder-badge').html(gfsItemsLength);
              $('.button.online-offer').show();
            } else {
              $('.button.online-offer').hide();
            }          
          });        
        }          
      },
      resize: function() {

        var headerHeight = $('#main-header').outerHeight(true);
        var footerHeight = $("#footer-wrap").outerHeight(true);
        var windowHeight = $(window).height();
        var combinedHeight = windowHeight - headerHeight - footerHeight;

        // Sync heights
        function equalHeight(group) {
          var tallest = 0;
          group.css('height','auto'); //otherwise only works once!
          group.each(function() {
            var thisHeight = $(this).outerHeight(true);
            if(thisHeight > tallest) {
              tallest = thisHeight;
            }
          });
          group.height(tallest);      
        } 

        // get tallest
        function getTallest(group) {
          var tallest = 0;
          group.each(function() {
            var thisHeight = $(this).outerHeight(true);
            if(thisHeight > tallest) {
              tallest = thisHeight;
            }
          });
          return tallest;      
        }

        // get smallest
        function getSmallest(group) {
          var smallest = null;
          group.each(function() {
            var thisHeight = $(this).height();
            if(smallest === null || thisHeight < smallest) {
              smallest = thisHeight;
            }
          });
          return smallest;
        }        

        // get URL parameter
        function getUrlParameter(name) {
          name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
          var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
          var results = regex.exec(location.search);
          return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }

        // set URL parameter
        function setUrlParameter(key, value) {
          var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
              urlQueryString = document.location.search,
              newParam = key + '=' + value,
              params = '?' + newParam;
          // If the "search" string exists, then build params from it
          if (urlQueryString) {
            var updateRegex = new RegExp('([\?&])' + key + '[^&]*');
            var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');
        
            if( typeof value === 'undefined' || value === null || value === '' ) { // Remove param if value is empty
              params = urlQueryString.replace(removeRegex, "$1");
              params = params.replace( /[&;]$/, "" );
            } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
              params = urlQueryString.replace(updateRegex, "$1" + newParam);
            } else { // Otherwise, add it to end of query string
              params = urlQueryString + '&' + newParam;
            }
          }
          // no parameter was set, remove the question mark
          params = params === '?' ? '' : params;
          window.history.replaceState({}, "", baseUrl + params);
        }

        // check for external links
        function checkForExternalLinks() {
          $('a').each(function() {
            var currentHref = this.href;

            if( location.hostname === this.hostname || !this.hostname.length ) {
              if (!$(this).hasClass('local')) {
                $(this).addClass('local');
              }
            } else {
              if (!$(this).hasClass('external')) {
                if (!$(this).hasClass('whatsapp-share-button')) {
                  $(this).addClass('external');
                }
              }              
            }
          });
        }
        checkForExternalLinks();

        // ie object-fit fallback
        function checkForIE() {
          var userAgent = window.navigator.userAgent;
          var ieReg = /msie|Trident.*rv[ :]*11\./gi;
          var ie = ieReg.test(userAgent);
          
          if(ie || (CSS.supports("object-position: center") === false)) {
            $(".image-wrap.aspect-ratio").each(function () {
              var $container = $(this);
              var imgUrl = $container.find("img").prop("src");
              var imgPos = $container.find("img").attr("data-object-position");
              var bgVideo = $container.find(".background-video"); 
              //console.log('imgPos: ' + imgPos);           
              if (imgUrl) {
                $container.css("backgroundImage", 'url(' + imgUrl + ')').addClass("ie-object-fit");
                if (imgPos) {
                  $container.css("background-position", imgPos);
                }                
              }
              if (bgVideo.length) {
                $container.addClass("ie-object-fit-video");             
              }              
            });
          }                          
        }
        checkForIE();

        // scrolling into view
        function isScrolledIntoView(elem){
            var $elem = $(elem);
            var $window = $(window);
        
            var docViewTop = $window.scrollTop();
            var docViewBottom = docViewTop + $window.height();
        
            var elemTop = $elem.offset().top;
            var elemBottom = elemTop + $elem.height();
        
            return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
        }       

        $(window).scroll(function(){
          $('video.play-in-view').each(function(){
            if (isScrolledIntoView($(this))) {
              $(this).get(0).play();
              //console.log('video is playing');
            } else if (!$(this).paused) {
              $(this).get(0).pause();
              //console.log('video paused');
            }
          });
        });

        $('video.auto-play').each(function(){
          $(this).get(0).play();
        });        

        $(window).scroll(function(){
          $('.embed-container.play-in-view').each(function(){
            var videoFrame = $(this).find('iframe');
            var videoUrl = videoFrame.prop('src'); 
            if (isScrolledIntoView($(this))) {
              videoFrame[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
              //console.log('video is playing');
            } else {
              videoFrame[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
              //console.log('video paused');
            }
          });
        });  

        $('.embed-container.auto-play').each(function(){
          var videoFrame = $(this).find('iframe');
          var videoUrl = videoFrame.prop('src'); 
          videoFrame[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
        });              

        // Sliders
        var resizeSliders = null;

        clearTimeout(resizeSliders);
        resizeSliders = setTimeout(
          function() {
            function renderCounter(slick,sliderId) {
              var slidesToShow;
              if(slick.activeBreakpoint!==null){
                slidesToShow = slick.breakpointSettings[slick.activeBreakpoint].slidesToShow;
              } else {
                slidesToShow = slick.options.slidesToShow;
              }
    
              if($('#'+sliderId).parents('.slider-wrapper').find('.slick-next').length>0){
                $('#'+sliderId).parents('.slider-wrapper').find('.counter').html( (slick.currentSlide+slidesToShow)/slidesToShow +' / ' + Math.ceil((slick.slideCount)/slidesToShow));
                $('#'+sliderId).parents('.slider-wrapper').find('.slider-control').show();
              } else {
                $('#'+sliderId).parents('.slider-wrapper').find('.counter').html('');
                $('#'+sliderId).parents('.slider-wrapper').find('.slider-control').hide();
              }
            } 
    
            $('.common-slider').each(function (idx, item) {
              var $this = $(this);

              if ($(this).hasClass('slick-initialized')) {
                if ($(this).hasClass('accordion-slider')) {
                  if (Foundation.MediaQuery.atLeast('large')) {
                    $(this).slick('resize');                    
                  } else {
                    $(this).slick('unslick');
                  }
                } else {
                  $(this).slick('resize');
                }   
              } else {
                var sliderId = "slider_" + idx;
                this.id = sliderId;
                $(this).attr('data-counter','slider_slick-control_' + idx);
                $(this).parents('.slider-wrapper').find('.slider-control').attr('id','slider_slick-control_' + idx);
                
                $(this).on('init', function(event, slick) {
                  //console.log('init');
                });

                $(this).on('reInit', function(event, slick){
                  //console.log('reInit');
                });
  
                $(this).on('setPosition', function(event, slick) {
                  //console.log('setPosition');
                  renderCounter(slick,sliderId);

                  if ($this.hasClass('auto-slider')) {
                    if (Foundation.MediaQuery.atLeast('large')) {
                      $this.parents('.slider-wrapper').on('mouseenter', '.slider-control', function() {
                        $this.slick('slickSetOption', 'autoplay', false).slick('slickPause');
                      });
                      $this.parents('.slider-wrapper').on('mouseleave', '.slider-control', function() {
                        $this.slick('slickSetOption', 'autoplay', true).slick('slickPlay');
                      }); 
                    } 
                  }  

                  if ($this.hasClass('sync-image-heights')) {
                    if (Foundation.MediaQuery.atLeast('large')) {
                      var $imageWraps = $(this).find('.image-wrap');
                      equalHeight($imageWraps); 
                    } 
                  }                                   
                });

                $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                  //console.log(nextSlide);
                });  

                $(this).on('afterChange', function(event, slick, currentSlide){
                  //console.log(currentSlide);
                });  

                $(this).on('edge', function(event, slick, direction){
                  //console.log('edge was hit');
                });                          

                $(this).on('destroy', function(event, slick){
                  //console.log('destroy');
                }); 

                $(this).slick({
                  appendArrows: $('#slider_slick-control_' + idx)                  
                });                   

              }
            });            

          },
          400
        );                     

        // ajax load more
        function ajax_load_more (buttonWrap, postsWrap, restartPosts) {
          var $current_button_wrap = buttonWrap;
          var $current_posts_wrap = postsWrap;
          var current_restart_posts = restartPosts; 

          var currentFieldIndex = $current_posts_wrap.attr('data-field-index');
          var currentPostsRelated = $current_posts_wrap.attr('data-posts-related');
          var currentHowManyPosts = $current_posts_wrap.attr('data-how-many-posts');
          var currentNumRowsMobile = $current_posts_wrap.attr('data-num-rows-mobile');
          var currentNumRowsDesktop = $current_posts_wrap.attr('data-num-rows-desktop');
                  
          var urlParamsMorePosts = getUrlParameter('moreposts-'+currentFieldIndex);

          var currentPostsPerPage = -1; 
          var currentMediaquery = '';

          if (currentHowManyPosts !== 'all') {
            if (Foundation.MediaQuery.is('small only')) {
                currentPostsPerPage =  currentNumRowsMobile;
            } 
            if (Foundation.MediaQuery.is('medium only')) {
                currentPostsPerPage =  currentNumRowsDesktop;
            }
            if (Foundation.MediaQuery.atLeast('large')) {
                currentPostsPerPage = currentNumRowsDesktop;
            }
          }

          if (Foundation.MediaQuery.is('small only')) {
              currentMediaquery = 'small';
          }
          if (Foundation.MediaQuery.is('medium only')) {
              currentMediaquery = 'medium';
          }
          if (Foundation.MediaQuery.is('large only')) {
              currentMediaquery = 'large';
          }
          if (Foundation.MediaQuery.atLeast('xlarge')) {
              currentMediaquery = 'xlarge';
          }      
          if (Foundation.MediaQuery.atLeast('xxlarge')) {
              currentMediaquery = 'xxlarge';
          }

          $.ajax({
            url : SiteParameters.ajax_url,
            type : 'GET',
            dataType: 'html',
            data: ({
              action: 'ajax_load_more',
              currentfieldindex: currentFieldIndex,
              currentrestartposts: current_restart_posts,
              currentpostsrelated: currentPostsRelated,
              currentpostsperpage: currentPostsPerPage,
              currentmoreposts: urlParamsMorePosts,
              currentmediaquery: currentMediaquery,
              currentpostid: SiteParameters.post_id
            }),      
            beforeSend: function (xhr) {
              //$current_button_wrap.find('.button').text('Loading...');
            },
            success: function(data){
              if(data) { 
                var parsedData = jQuery.parseJSON(data);
                //console.log("CurrentPostCount: " + parsedData.currentPostCount);
                //console.log("CurrentFoundPosts: " + parsedData.currentFoundPosts);
                //console.log("CurrentShownPosts: " + parsedData.currentShownPosts);
                //console.log("CurrentPostsPerPage: " + parsedData.currentPostsPerPage);               

                if (current_restart_posts){
                  $current_posts_wrap.html(parsedData.output);
                } else {
                  $current_posts_wrap.append(parsedData.output);
                }              

                $current_posts_wrap.find('.post-item').each(function(){
                  var $this = $(this);

                  $this.find('.item-image').not('[data-resize]').foundation();                     
                });

                if ( parsedData.currentShownPosts >= parsedData.currentFoundPosts ){
                  $current_button_wrap.hide();
                } else {
                  $current_button_wrap.show();
                }

              } else {
                $current_button_wrap.hide();
              }
            },
            error: function(data) {
              console.log("Error: ", data);
              return false;
            },
            complete: function() {    
              //console.log("Complete");                         
            }
          });
        }        

        var resizeMorePosts = null;

        clearTimeout(resizeMorePosts);
        resizeMorePosts = setTimeout(
          function() { 
            $('.load-more-wrap').each(function(){
              var $currentPostsWrap = $(this).find('.load-more-posts-wrap');
              var $currentButtonWrap = $(this).find('.load-more-button-wrap');
              var currentUrlParamIndex = $currentPostsWrap.attr('data-field-index');

              if ( getUrlParameter('moreposts-'+currentUrlParamIndex) === '' ) {
                $currentPostsWrap.html('');
                ajax_load_more($currentButtonWrap, $currentPostsWrap);
                //console.log("no moreposts");
              } else {
                $currentPostsWrap.html('');
                ajax_load_more($currentButtonWrap, $currentPostsWrap, true);
                //console.log("moreposts set");
              }
            });                                  
          },
          400
        ); 
              
        $('.load-more-wrap').on('click', '.load-more-button', function() {
          var $currentPostsWrap = $(this).closest('.load-more-wrap').find('.load-more-posts-wrap');
          var $currentButtonWrap = $(this).closest('.load-more-button-wrap');
          var currentUrlParamIndex = $currentPostsWrap.attr('data-field-index');
          var urlParamsMorePosts = getUrlParameter('moreposts-'+currentUrlParamIndex);
          var parsedUPMP = parseInt(urlParamsMorePosts);
          var morePostsNum = 1;
          if ( typeof urlParamsMorePosts !== 'undefined' && urlParamsMorePosts !== null && !isNaN(parsedUPMP)) {
            morePostsNum = parsedUPMP + 1;
          }
          setUrlParameter('moreposts-'+currentUrlParamIndex, morePostsNum);
          ajax_load_more($currentButtonWrap, $currentPostsWrap);
        });


        // set padding for standard-section items
        $('.standard-section.left-right .standard-item-wrap').each(function(){
          if (Foundation.MediaQuery.atLeast('medium')) {
            var $firstItem = $(this).find('#item-index-1');
            var $secondItem = $(this).find('#item-index-2');
            if ( $firstItem.find('.padding-right').length && ($secondItem.find('.padding-left:not(.image-item-wrap)').length || $secondItem.hasClass('text_item')) ) {
               $firstItem.find('.padding-right').css({'padding-right': 0});
            }
            if ( $secondItem.find('.padding-left').length && ($firstItem.find('.padding-right:not(.image-item-wrap)').length || $firstItem.hasClass('text_item')) ) {
               $secondItem.find('.padding-left').css({'padding-left': 0});
            }                 
          } else {
            
          } 
        });

        $('.standard-section.top-bottom .standard-item-wrap').each(function(){
          var $firstItem = $(this).find('#item-index-1');
          var $secondItem = $(this).find('#item-index-2');
          if ( $firstItem.find('.padding-bottom').length && ($secondItem.find('.padding-top').length || $secondItem.hasClass('text_item')) && !$secondItem.hasClass('image_text_item') ) {
             $firstItem.find('.padding-bottom').css({'padding-bottom': 0});
          }
          if ( $secondItem.find('.padding-top').length && ($firstItem.find('.padding-bottom').length || $firstItem.hasClass('text_item')) && !$firstItem.hasClass('image_text_item') ) {
             $secondItem.find('.padding-top').css({'padding-top': 0});
          }    
          if ($firstItem.hasClass('image_text_item')) {
            $firstItem.find('.padding-bottom').css({'padding-bottom': 0});
          }    
          if ($secondItem.hasClass('image_text_item')) {
            $secondItem.find('.padding-bottom').css({'padding-bottom': 0});
          }                        
        });

        var resizeSections = null;

        clearTimeout(resizeSections);
        resizeSections = setTimeout(
          function() { 
            // set height for standard-section items with divider
            $('.standard-section.has-divider').each(function(){
              if (Foundation.MediaQuery.atLeast('medium')) {
                var $sectionImages = $(this).find('img');       
                var smallestImgHeight = getSmallest($sectionImages);
                $sectionImages.css({'max-height': smallestImgHeight, 'width': 'auto'});                 
              } else {
                
              } 
            });                                            
          },
          400
        );    

        // Sticky breadcrumbs
        if (Foundation.MediaQuery.atLeast('xlarge')) {
          if ($(window).scrollTop() > 80) {
            $('.breadcrumbs-wrap').addClass('sticky');
          } else {
            $('.breadcrumbs-wrap').removeClass('sticky');
          }                 
        } else {
          $('.breadcrumbs-wrap').addClass('sticky');           
        }        

        $(window).scroll(function () {
          var currentScrollTop = $(window).scrollTop();
          if (Foundation.MediaQuery.atLeast('xlarge')) {
            if (currentScrollTop > 80) {
              $('.breadcrumbs-wrap').addClass('sticky');
            } else {
              $('.breadcrumbs-wrap').removeClass('sticky');                   
            }
          } else {
            $('.breadcrumbs-wrap').addClass('sticky');            
          }       
        });                                                

        // Sticky footer
        function stickyFooter() {
          if (Foundation.MediaQuery.atLeast('large')) {
            $("main.main").css('min-height', combinedHeight + "px");
          } else {
            $("main.main").css('min-height', "100vh");          
          }                    
        }

        if ($( "#footer-wrap" ).length) {
          stickyFooter();
        }       

      },
      windowload: function() {
        //console.log('loaded');
      
        // fire initial resize
        $(window).trigger('resize');   
        $('html').addClass('window-loaded');     
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      },
      resize: function() {

      },
      windowload: function() {

      }
    },
    'page_template_template_layouts': {
      init: function() {

      },
      finalize: function() {

      },
      resize: function() {

      },
      windowload: function() {

      }
    }    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
        //UTIL.fire(classnm, 'resize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
      //UTIL.fire('common','resize');
    },
    resizeEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'resize');
      });

      UTIL.fire('common','resize');

    },
    windowLoadEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm, 'windowload');
      });

      UTIL.fire('common','windowload');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).on("load",UTIL.windowLoadEvents);

  // Resize Events
  var trackWindowWidth = $(window).width();

  $(window).resize(function (trackResizeEvent) {
      //console.log(!trackResizeEvent.isTrigger ? ' resize' : ' triggerd');
  
      // Check window width has actually changed and it's not just iOS triggering a resize event on scroll || the resize event was caused by $(window).trigger('resize');
      if (($(window).width() !== trackWindowWidth) || trackResizeEvent.isTrigger) {
          // Update the window width for next time
          trackWindowWidth = $(window).width();

          UTIL.resizeEvents();
      }  
  });


})(jQuery); // Fully reference jQuery after this point.

